<script>
import { of, defer, merge } from 'rxjs'
import { share, mapTo, map } from 'rxjs/operators'
import { api } from '@/api'

export default {
  data() {
    return {
      recordsSearch: '',
      recordsHeaders: [
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Step',
          value: 'step',
          align: 'left',
        },
        {
          text: 'Drawing tool',
          value: 'drawing',
          align: 'center',
        },
        {
          text: 'Submited at',
          value: 'date',
          filterable: false,
        },
      ],
      password: window.sessionStorage.getItem('journey'),
    }
  },
  subscriptions() {
    const records$ = defer(() => api('journey', 'list')).pipe(
      map((records = []) =>
        records
          .filter(
            record =>
              // is a submited journey
              record != void 0 && record.journey != void 0
            // record.journey.includes('"step":18')
          )
          .map(record => ({
            ...record,
            name: this.journeyName(record.journey),
            drawing: this.journeyDrawing(record.journey),
            step: this.journeyStep(record.journey),
          }))
      ),
      share()
    )

    const loadingRecords$ = merge(of(true), records$.pipe(mapTo(false)))

    return {
      records$,
      loadingRecords$,
    }
  },
  methods: {
    journeyName(journey) {
      if (journey == void 0 || String(journey).trim() === '') return ''

      const [, match] = journey.match(/18-vorname":"([^"]+)/) || []

      return match || ''
    },
    journeyStep(journey) {
      if (journey == void 0 || String(journey).trim() === '') return ''

      const [, match = 0] = journey.match(/step":([^,]+)/) || []

      return `${String(match).padStart(2, '0')} / 18`
    },
    journeyDrawing(journey) {
      if (journey == void 0 || String(journey).trim() === '') return ''

      return journey.includes('"12":')
        ? '✓'
        : journey.includes('"12-cancel":')
        ? '-'
        : '-'
    },
    recordClick(item) {
      this.$router.push({
        name: 'record',
        params: { email: item && item.email },
      })
    },
    submit() {
      window.sessionStorage.setItem('journey', this.password)
    },
  },
}
</script>
<template>
  <div v-if="password === 'xblack'" class="px-4 px-md-12">
    <div class="mx-auto py-6 pb-10 pt-md-10 container">
      <div class="mb-2 d-flex justify-space-between align-center">
        <p class="mb-0 text-h6 d-flex flex-column d-md-block">
          Küchendesigns
        </p>

        <v-text-field
          type="search"
          outlined
          dense
          name="search_record"
          placeholder="Search"
          clearable
          :value="$route.query.q"
          @input="$router.replace({ query: { ...$route.query, q: $event } })"
          hide-details
          prepend-inner-icon="mdi-magnify"
          style="max-width: clamp(10rem, 47vw, 20rem);"
        >
        </v-text-field>
      </div>

      <!-- :custom-filter="customFilter" -->
      <v-data-table
        :headers="recordsHeaders"
        :items="records$ && !(records$ instanceof Error) ? records$ : []"
        :items-per-page="50"
        :search="$route.query.q"
        item-key="id"
        :loading="loadingRecords$"
        mobile-breakpoint="md"
        @click:row="recordClick"
      >
        <template v-slot:item.date="{ item }">
          {{ new Date(item.date).toDateString().slice(-11) }}
          <!-- {{ item.date }} -->
        </template>
      </v-data-table>
    </div>
  </div>
  <div v-else class="d-flex justify-center align-center" style="height: 100%;">
    <v-text-field
      type="password"
      v-model="password"
      style="max-width: 10rem;"
      @input="submit"
      label="Password"
    ></v-text-field>
  </div>
</template>
